import PlacesAutocomplete from 'stimulus-places-autocomplete'
import { getAddressObject } from '../src/plugins/GoogleAddressParser';

export default class extends PlacesAutocomplete {
  static targets = ["addressLine1", "addressLine1", "locationName", "wrapper"];

  initAutocomplete() {
    super.initAutocomplete();
    this.repositionPacContainer();
  }

  placeChanged() {
    super.placeChanged();
    const place = this.autocomplete.getPlace();
    if (place) {
      const locationName = place.formatted_address || place.name;
      if (this.locationNameTarget) this.locationNameTarget.value = locationName;

      const address = getAddressObject(place.address_components);
      if (this.addressLine1Target) this.addressLine1Target.value = address?.addressLine1;
      if (this.addressLine2Target) this.addressLine2Target.value = address?.addressLine2;
      if (this.countryTarget) this.countryTarget.value = address?.country;
    }
  }

  positionResults = () => {
    const pacContainer = document.querySelector(".pac-container");
    if (pacContainer) {
      if (pacContainer.parentElement !== this.element) {
        this.wrapperTarget.appendChild(pacContainer);
      }
      pacContainer.style.opacity = "0";
      setTimeout(() => {
        pacContainer.style.removeProperty("top");
        pacContainer.style.removeProperty("left");
        pacContainer.style.removeProperty("width");
        pacContainer.style.width = this.element.offsetWidth + "px";
        pacContainer.style.top = this.element.offsetHeight + "px";
        pacContainer.style.display = "absolute";
        pacContainer.style.opacity = "1";
      }, 1);
    }
  }

  repositionPacContainer = () => {
    const targetNode = document.body;

    // Define the callback for the mutation observer
    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Check if the added nodes contain an element with the class 'pac-container'
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('pac-container')) {
              observer.disconnect();
              this.wrapperTarget.appendChild(node);
            }
          });
        }
      }
    };

    // Set up the observer options
    const observerOptions = {
      childList: true,    // Watches for added or removed child elements
      subtree: true       // Also watch all descendants of the target node
    };

    // Create an instance of MutationObserver and pass the callback
    const observer = new MutationObserver(callback);

    // Start observing the target node with the configured options
    observer.observe(targetNode, observerOptions);
  };

  // You can set the Autocomplete options in this getter.
  get autocompleteOptions() {
    return {
      fields: ['formatted_address', 'name', 'adr_address', 'address_components', 'geometry'],
    }
  }
}
