import { Controller } from "@hotwired/stimulus";
import { i18n } from "../src/custom/Internationalization";

export default class extends Controller {
  static targets = ["control", "conditionalQuestion"];

  connect() {
    if (this.controlTargets.length > 0) {
      this.controlTargets.forEach((control) => {
        if (control.nodeName === "SELECT") {
          this.enableChildren(control.value, false);
          this.disableChildren(control.options, control.value);
        }
      });
    }
    document.dispatchEvent(new Event("rebind-validation"));
  }

  subQuestionDisplay(event) {
    const select = event.currentTarget;
    this.enableChildren(select.value, false);
    this.disableChildren(select.options, select.value);
    document.dispatchEvent(new Event("rebind-validation"));
  }

  enableChildren(answerId, clearValue) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentAnswerId === answerId) {
        question.classList.remove("tw-hidden");
        this.enableField(question)

        if (clearValue === true) {
          this.clearField(question);
        }

        // This direct call works if the question element IS the form field itself
        this.setRequired(question);
      }
    });
  }

  disableField(field) {
    field.disabled = true;
    field.classList.add("disabled");
    field.querySelectorAll(".conditional-question").forEach((subfield) => {
      subfield.disabled = true;
      subfield.classList.add("disabled");
    });
  }

  enableField(field) {
    field.removeAttribute("disabled");
    field.classList.remove("disabled");
    field.querySelectorAll(".conditional-question").forEach((subfield) => {
      subfield.removeAttribute("disabled");
      subfield.classList.remove("disabled");
    });
  }

  setRequired(field) {
    if (field.dataset.conditionallyRequired === "true") {
      field.dataset.formValidate = true;
      if (field.getAttribute("type") !== "checkbox") {
        field.dataset.formValidateRequired = true;
      }
      field.dataset.justValidateFallbackDisabled = false;
    }

    field.querySelectorAll("[data-form-validate-required], [data-conditionally-required]").forEach((nestedField) => {
      this.setRequired(nestedField);
    });
  }

  setNotRequired(field) {
    field.dataset.justValidateFallbackDisabled = true;
    field.dataset.formValidate = false;
    if (field.getAttribute("type") !== "checkbox") {
      field.dataset.formValidateRequired = false;
    }

    field.querySelectorAll("[data-form-validate-required], [data-conditionally-required]").forEach((nestedField) => {
      this.setNotRequired(nestedField);
    });
  }

  disableDescendents(parentId) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentIds.includes(parentId)) {
        question.classList.add("tw-hidden");
        this.clearField(question);
        this.setNotRequired(question);
        this.disableField(question);
      }
    });
  }

  disableChildren(options, selected) {
    const optionsArray = Array.from(options);
    if (optionsArray) {
      optionsArray.forEach((option) => {
        if (option.value && option.value !== selected) {
          this.conditionalQuestionTargets.forEach((select) => {
            if (select.dataset.parentAnswerId === option.value) {
              this.disableField(select);

              this.disableDescendents(select.dataset.questionId);
              select.classList.add("tw-hidden");
              this.clearField(select);
              this.setNotRequired(select);
            }
          }, this);
        }
      });
    }
  }

  clearField(wrapper) {
    // The field passed in is a wrapper, we want to get the actual form elements
    wrapper.querySelectorAll(".conditional-question").forEach((field) => {
      if (
        field.tagName.toLowerCase() === "input" &&
        field.getAttribute("type") !== "checkbox" &&
        field.getAttribute("type") !== "radio"
      ) {
        field.value = null;
      } else if (field.tagName.toLowerCase() === "div") {
        const selectField = field.querySelector("select");
        if (selectField != null) {
          selectField.value = null;
          selectField.selectedIndex = -1;
        }
        const textElement = field.querySelector(".text");
        if (textElement != null) {
          textElement.innerText = i18n.t("placeholders.please_select");
        }
      } else {
        field.checked = false;
      }
    });
  }
}
